<template>
  <div>

    <b-overlay
      :show="$apollo.loading || mutationLoading"
      rounded="sm"
    >
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search meeting"
            type="text"
          />
          <b-button
            v-b-modal.scheduleMeetingModal
            variant="primary mr-1"
          >
            <feather-icon icon="PlusIcon" />
            Schedule Meeting
          </b-button>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'title'">
            {{ props.row.title }} <br> <small class="text-capitalize"><b-badge variant="light-primary">{{
              props.row.type
            }}</b-badge></small>

          </span>
          <!-- Column: Action -->
          <span
            v-else-if="props.column.field === 'action'"
            class="d-flex"
          >
            <b-button
              v-b-tooltip="'Update Links'"
              class="mr-50"
              size="sm"
              variant="outline-primary"
              @click="selectedRow = props.row.originalIndex; $bvModal.show('updateLinks')"
            >
              <feather-icon
                icon="LinkIcon"
              />
            </b-button>
            <b-button
              v-b-tooltip="'Add Participants'"
              size="sm"
              variant="outline-primary"
              @click="selectedRow = props.row.originalIndex; $bvModal.show('addParticipants')"
            >
              <feather-icon
                icon="UserPlusIcon"
              />
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'buttons'">
            <b-button
              :href="prependHttps(props.row.meeting_link)"
              size="sm"
              class="mb-25 mr-25"
              target="_blank"
              variant="outline-primary"
            >Join meeting</b-button>
            <b-button
              v-if="props.row.recording_link"
              :href="prependHttps(props.row.recording_link)"
              size="sm"
              target="blank"
              variant="outline-primary"
            >View recording</b-button>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
    <b-modal
      id="scheduleMeetingModal"
      ok-title="Schedule"
      size="lg"
      title="Schedule Meeting"
      @ok="addMeeting"
    >
      <b-row>

        <b-col md="6">
          <b-form-group
            label="Operation"
            label-for="operation"
          >
            <treeselect
              id="operation"
              v-model="operationsId"
              :options="operations"
              :show-count="true"
              disable-branch-nodes
              placeholder="Select Operation"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Title"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="title"
              placeholder="Meeting title"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Link"
            label-for="meetingLink"
          >
            <b-form-input
              id="meetingLink"
              v-model="meetingLink"
              placeholder="Meeting Link"
            />
          </b-form-group>
        </b-col>

        <!-- start date and time -->
        <b-col md="6">
          <b-form-group
            class="mb-2"
            label="Start date & time"
            label-for="StartDateAndTime"
          >
            <flat-pickr
              id="StartDateAndTime"
              v-model="beginTime"
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
              class="form-control"
              placeholder="Select start date & tIme"
            />
          </b-form-group>
        </b-col>
        <!-- end date and time -->
        <b-col md="6">
          <b-form-group
            class="mb-2"
            label="End date & time"
            label-for="EndDateAndTime"
          >
            <flat-pickr
              id="EndDateAndTIme"
              v-model="endTime"
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate: beginTime }"
              class="form-control"
              placeholder="Select end date & tIme"
            />
          </b-form-group>
        </b-col>

        <!-- meeting type-->
        <b-col md="6">
          <b-form-group
            class="mb-2"
            label="Select meeting type"
            label-for="MeetingType"
          >
            <v-select
              id="MeetingType"
              v-model="type"
              :options="meetingTypeOptions"
              placeholder="Select meeting type"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-modal>
    <b-modal
      v-if="rows.length"
      id="updateLinks"
      ok-title="Update"
      title="Update Meeting Links"
      @ok="updateLinks"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Meeting Link"
            label-for="updateMeetingLink"
          >
            <b-form-input
              id="updateMeetingLink"
              v-model="rows[selectedRow].meeting_link"
              placeholder="Meeting Link"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Recording Link"
            label-for="updateRecordingLink"
          >
            <b-form-input
              id="updateRecordingLink"
              v-model="rows[selectedRow].recording_link"
              placeholder="Recording Link"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="addParticipants"
      ok-only
      ok-title="Update"
      title="Add Participants"
      @ok="updateParticipants"
    >
      <b-form-group
        label="Select Participant"
        label-for="selectParticipant"
      >
        <treeselect
          id="selectParticipant"
          v-model="participants"
          :options="associations"
          value-consists-of="LEAF_PRIORITY"
          show-count
          show-count-of="LEAF_DESCENDANTS"
          multiple
          placeholder="Select Participant"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>

import {
  BBadge, BButton, BFormGroup, BFormInput, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { formatDate } from '@/@core/utils/utils'
import { mapGetters } from 'vuex'

export default {
  components: {
    BBadge,
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    vSelect,
    Treeselect,
    flatPickr,
  },
  data() {
    return {
      title: '',
      type: '',
      beginTime: '',
      endTime: '',
      meetingLink: '',
      operationsId: null,
      operations: [
        {
          id: '2',
          label: 'Requests',
          children: [],
        },
        {
          id: '3',
          label: 'Mentoring',
          children: [],
        },
        {
          id: '5',
          label: 'General',
          children: [{
            id: '',
            label: 'General',
          }],
        },
      ],
      status: '',
      mutationLoading: false,
      meetingTypeOptions: [
        'webinar', 'one-on-one', 'multi participant',
      ],
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Purpose',
          field(row) {
            if (row.requesttable) return row.requesttable.title
            return row.programs_progressstagetable ? row.programs_progressstagetable.title : ''
          },
        },
        {
          label: 'Meeting',
          field: 'title',
        },
        {
          label: 'Time',
          width: '10rem',
          field(row) {
            if (!row.begin_time) return '-'
            return `${formatDate(row.begin_time)} - ${formatDate(row.end_time)}`
          },
        },

        {
          label: 'Operation',
          field(row) {
            if (!row.requesttable && !row.programs_progressstagetable) {
              return 'General'
            }
            if (row.requesttable) {
              return row.requesttable.type
            }
            return 'Milestone'
          },
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: '-- No Filter --', // placeholder for filter input
            filterDropdownItems: [
              {
                value: 'General',
                text: 'General',
              },
              {
                value: 'Milestone',
                text: 'Milestone',
              },
              {
                value: 'Request',
                text: 'Request',
              },
              {
                value: 'mentor',
                text: 'Mentoring',
              },
              {
                value: 'partner',
                text: 'Partner Services',
              },
            ],
          },
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Links',
          field: 'buttons',
          width: '11rem',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      selectedOperation: null,
      associations: [
        {
          id: '0',
          label: 'Startups',
          children: [
            {
              id: '0-0',
              label: 'Incubatee',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-1',
              label: 'Graduate',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-2',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-3',
              label: 'Pre-Incubatee',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '1',
          label: 'Students',
          children: [
            {
              id: '1-0',
              label: 'In-house',
              children: [],
              isDisabled: true,
            },
            {
              id: '1-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '2',
          label: 'Mentors',
          children: [
            {
              id: '2-0',
              label: 'In-house',
              children: [],
              isDisabled: true,
            },
            {
              id: '2-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '3',
          label: 'Partners',
          children: [
            {
              id: '3-0',
              label: 'In-House',
              children: [],
              isDisabled: true,
            },
            {
              id: '3-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      selectedOrg: 'user/getSelectedOrgDetails',
    }),
    participants: {
      get() {
        if (!this.rows[this.selectedRow]) return []
        return this.rows[this.selectedRow].programs_meetparticipanttables.map(e => e.user_association_id)
      },
      set(e) {
        const participants = []
        // eslint-disable-next-line no-return-assign
        e.forEach((v, i) => participants[i] = {
          user_association_id: v,
          schedule_id_id: this.rows[this.selectedRow].id,
        })
        this.rows[this.selectedRow].programs_meetparticipanttables = participants
      },
    },
    milestoneId() {
      if (!this.operationsId) return null
      return this.operationsId.split('-')[0] === 'milestone' ? this.operationsId.split('-')[1] : null
    },
    requestId() {
      if (!this.operationsId) return null
      return this.operationsId.split('-')[0] === 'request' ? this.operationsId.split('-')[1] : null
    },
  },
  methods: {
    toTimestamp(strDate) {
      const datum = Date.parse(strDate)
      return datum / 1000
    },
    addMeeting() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          insert_programs_scheduletable_one(object: {begin_time: "${this.beginTime}", end_time: "${this.endTime}", meeting_link: "${this.meetingLink}", milestone_id: ${this.milestoneId}, request_id: ${this.requestId}, status: "scheduled", title: "${this.title}", type: "${this.type}", programs_meetparticipanttables: {data: {user_association_id: ${this.$route.params.id}}}}) {
            id
          }
        }`,
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'EMeet created successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create EMeet',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateLinks() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_programs_scheduletable_by_pk(pk_columns: {id: ${this.rows[this.selectedRow].id} }, _set: {recording_link: "${this.rows[this.selectedRow].recording_link}", meeting_link: "${this.rows[this.selectedRow].meeting_link}"}) {
            id
          }
        }`,
        update: (store, { data: { update_programs_scheduletable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_programs_scheduletable_by_pk.id ? 'Updated successfully' : 'Failed to update',
              icon: update_programs_scheduletable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_programs_scheduletable_by_pk.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateParticipants() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($objects: [programs_meetparticipanttable_insert_input!]!) {
          delete_programs_meetparticipanttable(where: {schedule_id_id: {_eq: ${this.rows[this.selectedRow].id}}}) {
            affected_rows
          }
          insert_programs_meetparticipanttable(objects: $objects) {
            affected_rows
          }
        }`,
        variables: {
          objects: this.rows[this.selectedRow].programs_meetparticipanttables,
        },
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        query startup_emeet{
          programs_scheduletable(order_by: {begin_time: desc}, where: {operations_id_id: { _is_null: true}, programs_meetparticipanttables: {user_association_id: {_eq: ${this.$route.params.id} }}}) {
            id
            title
            type
            begin_time
            end_time
            meeting_link
            recording_link
                       status
            requesttable {
              title
              type
            }
            programs_progressstagetable {
              title
            }
            programs_meetparticipanttables {
              user_association_id
            }
          }
        }`
      },
      update: data => data.programs_scheduletable,
    },
    operations: {
      query() {
        return gql`
        query startup_emeet_operations {
          requesttable(where: { _or: [
    { assignee_id: {_eq: ${this.$route.params.id} }},
    { requester_id: {_eq: ${this.$route.params.id} }}]}, order_by: {created_at: desc}) {
            requesttable_id
            title
            type
          }
          programs_progressstagetable(where: { startup_association_id: {_eq: ${this.$route.params.id}}} ,order_by: {stage_number: desc}) {
            id
            title
          }
        }`
      },
      update(data) {
        const { operations } = this
        const types = ['request', 'mentor']
        data.requesttable.forEach(e => {
          operations[types[e.type]].children.push({
            label: e.title,
            id: `request-${e.requesttable_id}`,
          })
        })
        return operations
      },
    },
    associations: {
      query() {
        return gql`
        query startup_associations($incubator_id: Int!) {
          users_associationtable(where: {incubator_id: {_eq: $incubator_id}}) {
            id
            role
            status
            users_customuser {
              full_name
            }
            users_organizationtable {
              title
            }
          }
        }`
      },
      variables() {
        return {
          incubatorId: this.selectedOrg.orgId,
        }
      },
      update(data) {
        const { associations } = this
        data.users_associationtable.forEach(e => {
          if (e.role === 'partner') {
            associations[3].children[e.status === 'accept' ? 0 : 1].children.push({
              label: e.users_organizationtable.title,
              id: e.id,
            })
            associations[3].children[e.status === 'accept' ? 0 : 1].isDisabled = false
          } else if (e.role === 'mentor') {
            associations[2].children[e.status === 'accept' ? 0 : 1].children.push({
              label: e.users_customuser.full_name,
              id: e.id,
            })
            associations[2].children[e.status === 'accept' ? 0 : 1].isDisabled = false
          } else if (e.role === 'student') {
            associations[1].children[e.status === 'accept' ? 0 : 1].children.push({
              label: e.users_customuser.full_name,
              id: e.id,
            })
            associations[1].children[e.status === 'accept' ? 0 : 1].isDisabled = false
          } else if (e.role === 'incubatee') {
            associations[0].children[0].children.push({
              label: e.users_organizationtable.title,
              id: e.id,
            })
            associations[0].children[0].isDisabled = false
          } else if (e.role === 'graduate') {
            associations[0].children[1].children.push({
              label: e.users_organizationtable.title,
              id: e.id,
            })
            associations[0].children[1].isDisabled = false
          } else if (e.role === 'startup') {
            associations[0].children[2].children.push({
              label: e.users_organizationtable.title,
              id: e.id,
            })
            associations[0].children[2].isDisabled = false
          } else if (e.role === 'pre-incubatee') {
            associations[0].children[3].children.push({
              label: e.users_organizationtable.title,
              id: e.id,
            })
            associations[0].children[3].isDisabled = false
          }
        })
        return associations
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
